.pattern-dashes {
  --s: 34px;
  /* control the size (the circle diameter) */
  --c1: #ecbe13;
  --c2: #309292;

  --_g: radial-gradient(calc(var(--s) / 2), var(--c1) 97%, #0000);
  background: var(--_g), var(--_g) calc(2 * var(--s)) calc(2 * var(--s)),
    repeating-conic-gradient(from 45deg, #0000 0 25%, var(--c2) 0 50%)
      calc(-0.707 * var(--s)) calc(-0.707 * var(--s)),
    repeating-linear-gradient(
      135deg,
      var(--c1) calc(var(--s) / -2) calc(var(--s) / 2),
      var(--c2) 0 calc(2.328 * var(--s))
    );
  background-size: calc(4 * var(--s)) calc(4 * var(--s));
}

.pattern-dashes {
  background: radial-gradient(
      circle at top right,
      transparent 10%,
      #9b0073 10%,
      #9b0073 20%,
      transparent 21%
    ),
    radial-gradient(
      circle at left bottom,
      transparent 10%,
      #9b0073 10%,
      #9b0073 20%,
      transparent 21%
    ),
    radial-gradient(
      circle at top left,
      transparent 10%,
      #9b0073 10%,
      #9b0073 20%,
      transparent 21%
    ),
    radial-gradient(
      circle at right bottom,
      transparent 10%,
      #9b0073 10%,
      #9b0073 20%,
      transparent 21%
    ),
    radial-gradient(circle at center, #9b0073 30%, transparent 31%);
  background-size: 1em 1em;
  background-color: #c0caff;
  opacity: 0.75;
  border: 3px solid rgb(35, 33, 33);
}
