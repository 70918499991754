.ctaGridCon:hover .MuiBox-root {
  transform: rotate(0);
  transition: all 0.25s linear;
}
.ctaGridCon:hover .MuiBox-root::before {
  width: 100%;
  top: 0;
  right: 0;
  transition: all 0.4s 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
