@font-face {
  font-family: Bellefair;
  src: url('./assets/font/Bellefair/Bellefair-Regular.ttf');
  font-display: swap;
}

* {
  font-family: Bellefair !important;
}

.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__canvas {
  box-shadow: 0 0 50px rgba(128, 128, 128, 0.2);
}
.pdf-container {
  width: 90vw;
}
@media print {
  .pdf-container {
    display: none;
  }
}
