// padding: 2rem 0;

.title {
  text-align: center;
}

.packages {
  display: flex;
  justify-content: center;
  margin: 0 0 2rem 0;
  // padding: 0 1rem;

  ul {
    display: flex;
    list-style-type: none;
    width: max-content;

    li {
      padding: 1rem 2rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.341);

      &:hover {
        background-color: #8338ec40;
      }
    }

    .active {
      border-bottom: 0.5rem solid #8338ec;
    }
  }
}

.listItem {
  cursor: 'pointer';
}

.destinations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 1rem 3rem;
  height: 100vh;
  overflow-y: auto;

  .destination {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    background-color: #8338ec14;
    border-radius: 1rem;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateX(0.4rem) translateY(-0.5rem);
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    img {
      width: 100%;
      max-height: 50%;
    }

    .info {
      display: flex;
      align-items: center;

      .services {
        display: flex;
        gap: 0.3rem;

        img {
          border-radius: 1rem;
          width: 2rem;
          padding: 0.3rem 0.4rem;
        }
      }

      display: flex;
      justify-content: space-between;
    }

    .distance {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 250) {
  .destinations {
    grid-template-columns: 90%;
    padding: 0;
  }
}

@media screen and (min-width: 250px) and (max-width: 768px) {
  .packages {
    ul {
      overflow: auto;

      li {
        padding: 0 0.5rem;
        font-size: 1em;
        padding-bottom: 1rem;
      }

      .active {
        border-bottom-width: 0.3rem;
      }
    }
  }

  .destinations {
    grid-template-columns: 1fr;
    padding: 0;
  }
}