:root {
  --text: hsl(0, 0%, 99%);
  --textDim: hsl(0, 0%, 60%);
  --backgroundConicGradient: conic-gradient(
    from 0.25turn at 77% 40%,
    #f69d3c,
    10deg,
    #1d0042,
    350deg,
    #f658a8
  );
  /* --primary: hsl(155, 100%, 65%); */
  --primary: #f69d3c;
  --primaryBg: #f69c3c19;
  --primaryHi: hsla(155, 100%, 75%, 10%);
  /* --primaryFg: hsl(155, 100%, 85%); */
  --primaryFg: #f69c3cab;
  --secondary: #f658a71d;
  /* --secondaryFg: #2b0aff; */
  --secondaryFg: #f658a8;
  --secondaryBg: hsla(156, 51%, 14%, 5%);
  --secondaryHi: hsla(156, 51%, 30%, 50%);
  --accent: hsl(155, 100%, 84%);
  --accentBg: hsla(155, 100%, 94%, 1%);
  --accentHi: hsla(155, 100%, 100%, 25%);
}
/* radial-gradient(circle at -20% 0, #2b0aff, #e93abf 31%, #f658a8 48%, #febf72) */
#outSystemContainer {
  font-family: 'Manrope';
  font-weight: 400;
  background-image: var(--backgroundConicGradient);
  color: var(--text);
  padding: 40px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

#outSystemContainer .menu:hover {
  color: var(--text);
  cursor: pointer;
}

#outSystemContainer .sitename {
  font-weight: bold;
}

#outSystemContainer .grid {
  position: absolute;
  height: 100%;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  z-index: -1;
}

#outSystemContainer .grid-svg {
  height: 80%;
  width: 80%;
  position: relative;
  z-index: 1;
}

#outSystemContainer .blur {
  height: 12rem;
  width: 12rem;
  background-color: var(--primary);
  filter: blur(100px);
  border-radius: 100px;
  z-index: 0;
  position: absolute;
}

#outSystemContainer .title {
  font-size: 10rem;
  font-weight: 700;
  letter-spacing: -0.2rem;
  display: flex !important;
  flex-direction: column;
  /* position: absolute; */
  justify-content: center;
  align-self: center;
  height: 100vh;
  z-index: 100;
}

#outSystemContainer .title > p {
  margin: 0;
  line-height: 10rem;
  width: auto;
}

#outSystemContainer .title > p:nth-child(1) {
  align-self: flex-start;
}

#outSystemContainer .title > p:nth-child(2) {
  color: var(--primary);
  align-self: flex-end;
}

#outSystemContainer .title > p:nth-child(3) {
  align-self: flex-end;
}

#outSystemContainer .material-icons {
  display: none;
  fill: var(--text);
}

#outSystemContainer .button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  height: 50px;
  width: 160px;
  z-index: 101;
}

#outSystemContainer button {
  height: 50px;
  width: 160px;
  clip-path: path(
    'M 0 25 C 0 -5, -5 0, 80 0 S 160 -5, 160 25, 165 50 80 50, 0 55, 0 25'
  );
  border: none;
  border-radius: 13px;
  background-color: var(--primaryBg);
  box-shadow: 0px -3px 15px 0px var(--primaryHi) inset;
  color: var(--primaryFg);
  font-family: 'Manrope';
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateY(0px);
  transition: all 0.2s ease;
}

#outSystemContainer span {
  width: 100px;
  height: 60px;
  background-color: var(--primaryHi);
  border-radius: 100%;
  filter: blur(20px);
  position: absolute;
  bottom: -50%;
  transition: all 0.2s ease;
}

#outSystemContainer .button:hover > span {
  opacity: 60%;
}

#outSystemContainer .button:hover > button {
  transform: translateY(5px);
}

#outSystemContainer .button.first {
  top: 10%;
  left: 20%;
}

#outSystemContainer .button.sec {
  top: 15vh;
  left: 13%;
}

#outSystemContainer .button.sec > button {
  background-color: var(--accentBg);
  box-shadow: 0px -3px 15px 0px var(--accentHi) inset;
  color: var(--accentFg);
}

#outSystemContainer .button.sec > span {
  background-color: var(--accentHi);
}

#outSystemContainer .button.third {
  top: 61vh;
  right: 15%; /* changed */
}

#outSystemContainer .button.third > button {
  background-color: var(--secondaryBg);
  box-shadow: 0px -3px 15px 0px var(--secondary) inset;
  color: var(--secondaryFg);
}

#outSystemContainer .button.third > span {
  background-color: var(--secondaryHi);
}

#outSystemContainer .top-right {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 50%;
}

#outSystemContainer .bottom-left {
  position: absolute;
  bottom: 0;
  right: 0; /* changed */
  z-index: -1;
  opacity: 50%;
}

@media screen and (max-width: 1000px) {
  #outSystemContainer .title {
    font-size: 4rem;
    line-height: 5rem;
  }

  #outSystemContainer .title > p {
    line-height: 5rem;
    letter-spacing: -0.3rem;
  }

  #outSystemContainer .material-icons {
    display: flex;
    align-items: center;
  }
}
