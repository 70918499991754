.auto-complete-search {
  padding: 8px 4px;
  font-family: inherit;
  width: 50%;
  z-index: 2;
  position: absolute;
  border-radius: 8px;
  border: 1px solid rgb(119, 119, 207);
  margin: 8px 8px 0 0;
}

.auto-complete-search:hover {
  border: 1px solid rgb(59, 59, 158);
}

.MuiBadge-anchorOriginBottomRightCircular {
  transform: translate(-10%, 25%);
}
