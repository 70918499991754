label.correct span {
  font-weight: bold;
  color: green;
  /* outline: 1px solid green; */
}

label.incorrect span {
  text-decoration: line-through;
  color: red;
}

label.correct.selected span {
  background-color: #d4f4dd;
  /* Light green */
}

label.incorrect.selected span {
  background-color: #f8d7da;
  /* Light red */
}

label.selected {
  position: relative;
}
label.selected::before {
  content: 'נבחר';
  position: absolute;
  right: -30px;
  top: 5%;
}
