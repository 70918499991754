#mgdalorSystemFeaturesCards {
  margin: 0;
  padding: 0;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  // align-items: center;
  min-height: 80vh;
  // background: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;

  section {
    padding-block: min(20vh, 2rem);
    width: calc(min(76.5rem, 90%));
    margin-inline: auto;
    color: #111;

    h2 {
      text-transform: capitalize;
      letter-spacing: 0.025em;
      font-size: clamp(2rem, 1.8125rem + 0.75vw, 2.6rem);
    }

    a {
      display: inline-block;
      text-decoration: none;
    }

    .radial {
      -webkit-text-fill-color: transparent;
      background-image: radial-gradient(circle at -20% 0, #2b0aff 60%, #e93abe);
      -webkit-background-clip: text;
      background-clip: text;
      display: block;
    }

    // .container {
    //   margin-top: 5em;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    // gap: 2rem;

    .card {
      max-height: 300px !important;
      width: 100%;
      &:nth-child(1) .box .icon .iconBox {
        background: #d3b19a;
      }
      &:nth-child(2) .box .icon .iconBox {
        background: #70b3b1;
      }

      &:nth-child(3) .box .icon .iconBox {
        background: #d05fa2;
      }

      .branding {
        color: darken(#d3b19a, 40%);
      }

      .packaging {
        color: darken(#70b3b1, 40%);
      }

      .marketing {
        color: darken(#d05fa2, 40%);
      }
      // }
    }
    .card-inner {
      position: relative;
      width: inherit;
      height: 100% !important;
      background: var(--clr);
      border-radius: 1.25rem;
      border-bottom-right-radius: 0;
      overflow: hidden;

      .box {
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 1.25rem;
        position: relative;
        overflow: hidden;

        .imgBox {
          position: absolute;
          inset: 0;

          img {
            width: 100%;
            height: 100%;
            max-height: 300px;
            object-fit: cover;
          }
        }

        .icon {
          position: absolute;
          bottom: -0.375rem !important;
          right: -0.375rem;
          width: 6rem;
          height: 6rem;
          background: var(--clr);
          border-top-left-radius: 50%;

          &:hover .iconBox {
            transform: scale(1.1);
          }

          &::before {
            position: absolute;
            content: '';
            bottom: 0.375rem;
            left: -1.25rem;
            background: transparent;
            width: 1.25rem;
            height: 1.25rem;
            border-bottom-right-radius: 1.25rem;
            box-shadow: 0.313rem 0.313rem 0 0.313rem #fff;
          }

          &::after {
            position: absolute;
            content: '';
            top: -1.25rem;
            right: 0.375rem;
            background: transparent;
            width: 1.25rem;
            height: 1.25rem;
            border-bottom-right-radius: 1.25rem;
            box-shadow: 0.313rem 0.313rem 0 0.313rem var(--clr);
          }

          .iconBox {
            position: absolute;
            inset: 0.625rem;
            background: #282828;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;

            span {
              color: #fff;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    .content {
      padding: 0.938rem 0.625rem;

      h3 {
        text-transform: capitalize;
        font-size: clamp(1.5rem, 1.3909rem + 0.4364vw, 1.8rem);
      }

      p {
        margin: 0.625rem 0 1.25rem;
        color: #565656;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.625rem;

        li {
          text-transform: uppercase;
          background: var(--clr-tag);
          color: #282828;
          font-weight: 700;
          font-size: 0.8rem;
          padding: 0.375rem 0.625rem;
          border-radius: 0.188rem;
        }
      }
    }
  }
}
