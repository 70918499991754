$base: 0.5rem;

.chevronsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chevron {
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 55%;
  background: #2c3e50;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY($base * 3.8);
  }

  66.6% {
    opacity: 1;
    transform: translateY($base * 5.2);
  }

  100% {
    opacity: 0;
    transform: translateY($base * 8) scale(0.5);
  }
}

.highlighted {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -0.125rem;
    left: -0.25rem;
    right: -0.5rem;
    height: 0.75rem;

    // Position the line behind the text so that
    // it is still easily readable
    z-index: -1;
    // The SVG is added as an SVG background image
    background-image: url(../../assets/home/undeline.svg);
    background-repeat: no-repeat;
    filter: hue-rotate(300deg);
    // This allows the SVG to flex in size to fit
    // any length of word. If the word is short,
    // the SVG will be stretched vertically, if it
    // is long, the SVG will be stretched horizontally.
    // The jagged nature of this particular SVG works
    // with this transforming.
    background-size: cover;
  }
}

p > strong {
  font-weight: 400;

  &::after {
    // Specific positioning for smaller text
    bottom: -0.2rem;
    height: 0.5rem;
    left: -0.25rem;
    right: -0.25rem;
  }
}

.smHighlighted {
  position: relative;
  display: inline-block;
}

.smHighlighted::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: tomato;
  z-index: -1;
  height: 2px;
  transform: skew(45deg) rotate(-1deg);
}
