.coupon {
  width: 400px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
  filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
  display: flex;
  align-items: stretch;
  position: relative;
  text-transform: uppercase;
}

.coupon::before,
.coupon::after {
  content: '';
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}

.coupon::before {
  left: 0;
  background-image: radial-gradient(
    circle at 0 50%,
    transparent 25px,
    gold 26px
  );
}

.coupon::after {
  right: 0;
  background-image: radial-gradient(
    circle at 100% 50%,
    transparent 25px,
    gold 26px
  );
}

.coupon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copounLeft {
  width: 20%;
  border-left: 2px dashed rgba(0, 0, 0, 0.13);
}

.copounLeft div {
  transform: rotate(-90deg);
  white-space: nowrap;
  font-weight: bold;
}

.copounCenter {
  flex-grow: 1;
  text-align: center;
}

.copounRight {
  width: 120px;
  background-image: radial-gradient(
    circle at 100% 50%,
    transparent 25px,
    #fff 26px
  );
}

.copounRight div {
  font-family: 'Libre Barcode 128 Text', cursive;
  font-size: 2.5rem;
  font-weight: 400;
  transform: rotate(-90deg);
}

.copounCenter h2 {
  background: #000;
  color: gold;
  padding: 0 10px;
  font-size: 2.15rem;
  white-space: nowrap;
}

.copounCenter h3 {
  font-size: 2.15rem;
}

.copounCenter small {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 2px;
}

@media screen and (max-width: 500px) {
  .coupon {
    display: grid;
    grid-template-columns: 1fr;
  }

  .copounLeft div {
    transform: rotate(0deg);
  }

  .copounRight div {
    transform: rotate(0deg);
  }
}

.shinyLayer {
  position: absolute;
  width: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  background-size: 200% 100%;
  /* background-position: 300% 0; */
  height: 100%;
  animation: shining 3s ease-in-out infinite forwards;
}

@keyframes shining {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: 100% 0;
  }
}
