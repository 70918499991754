.successCard {
  position: relative;
}
.successCard::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: 0.5s ease all;
}
.successCard .successCardMedia {
  transition: 0.5s ease all;
  width: 100%;
}
.successCard:hover .successCardMedia {
  transform: scale(1.05);
}
.masked.animated {
  animation: maskFade 3s 2s 1 forwards ease-out;
}

.masked {
  display: block;
  width: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
  mask-image: url(./2779948.svg);
  mask-mode: alpha;
}
@keyframes maskFade {
  10% {
    mask-size: 150%;
  }
  20% {
    mask-size: 200%;
  }
  30% {
    mask-size: 250%;
  }
  40% {
    mask-size: 300%;
  }
  50% {
    mask-size: 350%;
  }
  60% {
    mask-size: 400%;
  }
  70% {
    mask-size: 450%;
  }
  80% {
    mask-size: 500%;
    mask-position: center bottom;
  }
  90% {
    mask-size: 550%;
    mask-position: center bottom;
  }
  100% {
    mask-size: 600%;
    mask-position: bottom;
    mask-image: none;
  }
}
