:root {
  --primary-color: #212121;
  --secondary-color: #bdbeca;
}

.contactContainer {
  height: 100%;
  position: absolute;
  top: -50%;
  // left: calc(50% - 277px / 10);
  margin: auto;
  transform: translate(-50%, -50%);
}

.btn {
  margin-top: 0 !important;
  position: relative;
  display: inline-block;
  width: 277px;
  height: 50px;
  // font-size: 1em;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 2rem;
  letter-spacing: 0.045em;
}

.btn svg {
  position: absolute;
  top: 0;
  left: 0;
}

.btn svg rect {
  //stroke: #EC0033;
  stroke-width: 4;
  stroke-dasharray: 353, 0;
  stroke-dashoffset: 0;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}

.btn span {
  background: rgb(255, 130, 130);
  background: -moz-linear-gradient(
    left,
    rgba(255, 130, 130, 1) 0%,
    rgba(225, 120, 237, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 130, 130, 1) 0%,
    rgba(225, 120, 237, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 130, 130, 1) 0%,
    rgba(225, 120, 237, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8282', endColorstr='#e178ed', GradientType=1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn:hover svg rect {
  stroke-width: 4;
  stroke-dasharray: 196, 543;
  stroke-dashoffset: 437;
}
