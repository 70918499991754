#introduction {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-style: normal;

  main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 90vh;
  }

  #s2 {
    background: #1d0042;
    min-height: 70vh;
    margin-top: -6px;
  }

  svg {
    position: relative;
    margin-top: -9vw;
  }

  h1 {
    font-size: clamp(3vw, 8.5vw, 99px);
    text-align: center;
  }

  #s1 h1 {
    font-weight: 800;
  }

  .pink {
    color: rgb(239, 208, 255);
    display: block;
  }

  .radial {
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(
      circle at -20% 0,
      #2b0aff,
      #e93abf 31%,
      #f658a8 48%,
      #febf72
    );
    -webkit-background-clip: text;
    background-clip: text;
    display: block;
  }

  .smaller {
    font-size: 0.35em;
    font-weight: 500;
  }
}
