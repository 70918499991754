$markerColor: #005596;
$markerInnerColor: #fff;
$markerSize: 30px;
$duration: 1s;

.container {
  width: $markerSize * 2;
  height: $markerSize * 1.8;
  position: absolute;
}

.marker {
  z-index: 1;
  position: absolute;
  top: -17%;
  left: 78%;
  // margin-left: -$markerSize/2;
  width: $markerSize;
  height: $markerSize;
  border-radius: $markerSize;
  background-color: $markerColor;
  animation: hover $duration infinite ease-in;
}

// the dot within
.marker:after {
  content: '';
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -calc(#{$markerSize} / 8);
  margin-top: -calc(#{$markerSize} / 8);
  width: calc(#{$markerSize}/ 4);
  height: calc(#{$markerSize}/ 4);
  border-radius: calc(#{$markerSize}/ 4);
  background-color: $markerInnerColor;
}

// the triangle
.marker:before {
  z-index: 0;
  content: '';
  position: absolute;
  top: 80%;
  left: 50%;
  margin-left: -calc(#{$markerSize} / 2.5);
  border-left: calc(#{$markerSize} / 2.5) solid transparent;
  border-right: calc(#{$markerSize} / 2.5) solid transparent;
  border-top: calc(#{$markerSize} / 2) solid $markerColor;
}

.pulse {
  position: absolute;
  top: -12.5%;
  left: calc(75% + calc(#{$markerSize} / 2));
  display: block;
  width: $markerSize;
  height: calc($markerSize / 3.5);
  background: skyblue;
  /* background alternative white */
  border-radius: 50%;
  transform: translate(-50%, 400%);
}

.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: skyblue;
  border-radius: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%) scale(1);
  animation: ripple $duration infinite ease-in;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }

  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@keyframes hover {
  0% {
    margin-top: 0px;
  }

  50% {
    margin-top: 10px;
  }
}

.tooltip {
  background: #6e6e6e;
  border-radius: 20%;
  box-sizing: border-box;
  opacity: 1;
  position: absolute;
  transition: all 0.15s linear;
  height: 35px;
  // display: flex;
  justify-content: center;
  display: block;

  .org-avatar {
    padding: 1px 2px;
    margin-bottom: 0;
  }

  .org-avatar > img {
    object-fit: fill;
    height: 30px;
    width: 30px;
    z-index: 2;
    // transform: translateY(-5%);
    margin: auto;
    margin-top: -0.3px;
    border-radius: 50%;
    z-index: 100;
  }

  &::after {
    // arrow
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #6e6e6e;
    content: '';
    // height: 0;
    position: absolute;
    bottom: -15px;
    transform: translateX(-12.5%) translateY(-5%);
    z-index: 1;
  }
}
