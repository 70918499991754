#scaleWithMgdalor {
  position: relative;
  overflow: hidden !important;
  background-image: linear-gradient(to bottom, #1d0042, #2e1065);
  --brand: 258, 85%, 67%;
  height: auto;

  .craqb {
    height: 200%;
  }

  .c325x {
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 12000ms linear infinite;
  }

  .c93o0 {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  @keyframes endless {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-245px);
    }
  }

  .ciogr {
    animation: endless 20s linear infinite;
  }

  .crf3j {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);

    filter: blur(12px);
  }

  .cdvmy {
    background: repeating-linear-gradient(
      transparent,
      transparent 48px,
      #fff 48px,
      #fff 49px
    );
  }

  .cku3o {
    -webkit-mask-image: radial-gradient(black, transparent 60%);
    mask-image: radial-gradient(black, transparent 60%);
  }

  .c3hw7 {
    animation-delay: -4000ms;
  }

  .cxli6 {
    background: linear-gradient(#0f172a, #0f172a) padding-box,
      conic-gradient(#94a3b8, #334155 25%, #334155 75%, #94a3b8 100%) border-box;
  }

  .cgqg9 {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .cm09z {
    background: repeating-linear-gradient(
      transparent,
      transparent 48px,
      #a855f7 48px,
      #a855f7 49px
    );
  }

  .cbw4r {
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 48px,
      #fff 48px,
      #fff 49px
    );
  }

  .cjyyp {
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 48px,
      #a855f7 48px,
      #a855f7 49px
    );
  }

  .cgeyp {
    --tw-blur: blur(2px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .cfhqf {
    animation-delay: -8000ms;
  }

  html:not(.c22xd) [data-aos='fade-up'] {
    transform: translate3d(0, 14px, 0);
  }

  .c22q0 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .w-500px {
    width: 500px;
  }

  .h-500px {
    height: 500px;
  }

  .for-now {
    // transform: translate(-50%, -50%);
    position: absolute;
    top: 0%;
    right: 35%;
  }

  .w-4rem {
    width: 4rem;
  }

  .h-4rem {
    height: 4rem;
  }

  .cnbxt {
    fill: #e2e8f0;
  }

  .cjpk6 {
    --tw-rotate: -14deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  @keyframes pulseLoop {
    0% {
      opacity: 0;
      transform: scale(0.25) translateZ(0);
    }

    30% {
      opacity: 0.4;
    }

    70% {
      opacity: 0;
    }

    80% {
      transform: scale(1) translateZ(0);
    }
  }

  /****************/

  :root {
    --productFeatureGraphicBackgroundColor: #f6f9fc;
    --productFeatureGraphicAspectRatio: 1.5;
    --productFeaturePrimaryAccentColor: #11efe3;
    --productFeatureSecondaryAccentColor: #96f;
  }

  .ProductFeatureCard__graphic {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    height: 275px;
    width: 100%;
  }

  .ProductFeatureCard__gradientLayer,
  .ProductFeatureCard__gradientLayer svg {
    width: 100%;
    height: 100%;
  }

  .ProductFeatureCard__ellipse--one {
    transform-origin: center;
    animation: ProductFeatureCardRotateOne 25s ease-in-out infinite;
  }

  .ProductFeatureCard__ellipse--two {
    transform-origin: center;
    animation: ProductFeatureCardRotateTwo 25s ease-in-out infinite;
  }

  @keyframes ProductFeatureCardRotateOne {
    0%,
    to {
      transform: scale(1.5) rotate(-45deg) translate(0);
    }

    25% {
      transform: scale(2.5) rotate(45deg) translate(20px, 50px);
    }

    50% {
      transform: scale(1.5) rotate(90deg) translate(100px, -100px);
    }

    75% {
      transform: scale(1.5) rotate(-45deg) translate(200px, 100px);
    }
  }

  @keyframes ProductFeatureCardRotateTwo {
    0%,
    to {
      transform: scale(1.5) rotate(-45deg) translate(150px, 200px);
    }

    25% {
      transform: scale(2) rotate(-10deg) translate(100px, 135px);
    }

    50% {
      transform: scale(1.5) rotate(0deg) translate(50px, 150px);
    }

    75% {
      transform: scale(2) rotate(15deg) translate(65px, 175px);
    }
  }

  /************/

  .gradient-blob {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }

  .gradient-blob__container {
    position: absolute;
    width: 100vw;
    -webkit-transition: left 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      top 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      width 2.2s cubic-bezier(0.52, 0, 0.2, 1);
    -o-transition: left 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      top 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      width 2.2s cubic-bezier(0.52, 0, 0.2, 1);
    transition: left 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      top 2.2s cubic-bezier(0.52, 0, 0.2, 1),
      width 2.2s cubic-bezier(0.52, 0, 0.2, 1);
  }

  .gradient-blob__container::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  .gradient-blob__image,
  .gradient-blob__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .gradient-blob__image {
    object-fit: contain;
    transition: opacity 2s cubic-bezier(0.52, 0, 0.2, 1);
  }

  .gradient-blob__image--blue,
  .gradient-blob__image--orange {
    opacity: 0;
  }

  .gradient-blob__container {
    user-select: none;
    top: -105%;
    left: -25%;
    width: 97%;
  }
}
