.fireAndElectericitySafety p {
  font-size: 2.5rem !important;
}
.fireAndElectericitySafety li {
  font-size: 32px;
}

@media screen and (max-width: 400px) {
  .fireAndElectericitySafety li {
    font-size: 60px;
    text-align: justify;
  }
  .titleToLiftInMobile {
    top: -400px;
  }
  .blockToLiftInMobile {
    top: -350px;
  }
}
@media screen and (max-width: 350px) and (min-width: 600px) {
  .fireAndElectericitySafety li {
    font-size: 50px;
  }
}
