.practiceFileThumbnail {
  height: 180px;
  width: 100%;
  object-fit: contain;
}

.pdfPage {
  height: 100% !important;
  width: 100% !important;
  min-width: none !important;
  min-height: none !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  box-sizing: content-box !important;
  border-radius: 8px;
}

.practiceFileView {
  height: 100% !important;
}

.viewPdfPage {
  height: 100% !important;
}

.viewFileDialogContent:hover > .fileViewPagination {
  background-color: rgba(7, 107, 194, 0.733);
  transition: all 200ms linear;
}

.practiceName {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shake {
  animation: shake 1000ms linear 3 both alternate;
}

@keyframes shake {
  0% {
    rotate: 15deg;
  }
  33% {
    rotate: 0deg;
  }
  66% {
    rotate: -15deg;
  }
  100% {
    rotate: 0deg;
  }
}
.practiceFileView {
  justify-content: center;
  display: flex;
}
.practiceFileView canvas {
  max-width: 800px !important;
}

.viewFile {
  height: calc(100% - 8px);
  width: 70%;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .viewFile {
    width: 100%;
  }
}

.pdfCanvas {
  width: 100% !important;
  height: 100% !important;
}
