:root {
  --second: #d1cdca94;
}

.flexcardNumber {
  width: 50%;
  min-width: 120px;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 10px 0px;
  border-radius: 25px 0;
  font-family: 'Jost', sans-serif;
  color: white;
  text-shadow: 0px 3px 5px black;
  font-weight: 500;
  font-size: 20px;
  position: relative;
}

.flexcardNumberGreen {
  background: #fcfcfc;
  background: -webkit-linear-gradient(to left, var(--second), #aed2bc);
  background: -moz-linear-gradient(to left, var(--second), #aed2bc);
  background: linear-gradient(to right, var(--second), #aed2bc);
  box-shadow: 0px 2px 2px #aed2bc;
}

.flexcardNumberGreen:before {
  background: #aed2bc;
}

.flexcardNumberGreen:after {
  background: #95b7a2;
}

.bgPattern {
  background: conic-gradient(at 10% 50%, #0000 75%, rgba(91, 94, 255, 0.47) 0),
    conic-gradient(at 10% 50%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(1 * 100px) calc(3 * 100px),
    conic-gradient(at 10% 50%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(2 * 100px) calc(1 * 100px),
    conic-gradient(at 10% 50%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(3 * 100px) calc(4 * 100px),
    conic-gradient(at 10% 50%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(4 * 100px) calc(2 * 100px),
    conic-gradient(at 50% 10%, #0000 75%, rgba(91, 94, 255, 0.47) 0) 0
      calc(4 * 100px),
    conic-gradient(at 50% 10%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(1 * 100px) calc(2 * 100px),
    conic-gradient(at 50% 10%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(2 * 100px) 0,
    conic-gradient(at 50% 10%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(3 * 100px) calc(3 * 100px),
    conic-gradient(at 50% 10%, #0000 75%, rgba(91, 94, 255, 0.47) 0)
      calc(4 * 100px) calc(1 * 100px),
    rgba(71, 212, 255, 0.04);
  background-size: 500px 500px;
}
