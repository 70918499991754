.bgPattern {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 10px
    ),
    repeating-linear-gradient(#444cf755, #444cf7);
}

.phonePulse {
  z-index: 3;
  border-radius: 50%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  -webkit-animation: pulse-animation 1s ease-out;
  animation: pulse-animation 1.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.3);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.3);
    opacity: 1;
  }

  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.entryDetailCard:hover .entryDetailIcon {
  /* background-color: #8fa8a2; */
  border: 2px solid #41514d;
  transition: all 0.3s;
}

.entryDetailCard:hover .entryDetailIcon svg {
  color: aliceblue;
  transition: all 0.3s;
}
