a.couponLink {
  display: block;
  padding: 33px 0 0 0;
  color: #313131;
  text-decoration: none;
}

/*  ================================================
            TICKET STYLING & COUPON EFFECT
    ================================================  */
.ticket {
  position: relative;
  display: table;
  width: 300px;
  /* height: 228px; */
  margin: auto;
  /* padding-bottom: 57px; */
  background: #f4f4f4;
  text-align: center;
  border-radius: 16px;
}

.ticket::before {
  content: '';
  position: absolute;
  top: 60%;
  left: 0;
  border-top: 20px solid transparent;
  z-index: 2;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #ebedfa;
}

.ticket::after {
  content: '';
  position: absolute;
  top: 60%;
  right: 0;
  border-top: 20px solid transparent;
  z-index: 2;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #ebedfa;
}

/*  ================================================
                    RIBBON EFFECT
    ================================================  */
.ribbonContact {
  position: absolute;
  display: block;
  top: -14px;
  right: -35px;
  width: 9rem;
  /* height: 110px; */
  overflow: hidden;
  z-index: 4;
}

.ribbonContact .label {
  position: relative;
  display: block;
  left: -10px;
  top: 23px;
  width: 8rem;
  padding: 10px 0;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background-color: #e85e68;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -moz-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  transform: rotate(45deg) translate3d(0, 0, 0);
}

.label:before,
.label:after {
  content: '';
  position: absolute;
  bottom: -4px;
  border-top: 4px solid #a71c26;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.label:before {
  left: 0;
}

.label:after {
  right: 0;
}

/*  ================================================
                        CONTENT
    ================================================  */
.couponSpan {
  display: block;
  font-size: 29px;
  color: #540c5d;
}

.couponStrong {
  display: block;
  font-size: 25px;
  color: #a52958;
  margin: 0 0 10px 0;
}

.couponEm {
  display: block;
  font-size: 20px;
  font-style: normal;
  color: #3c7c32;
  border-top: 2px dashed rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  /* transform: translateY(-66%); */
  z-index: 1;
}
