.confetti-button {
  position: relative;
  /* display: inline-block; */
}

.confetti-container {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 1;
  animation: explodeConfetti 3s linear;
  right: 70%;
  top: 16%;
}

@keyframes explodeConfetti {
  0% {
    transform: translate(-120%, 80%) scale(0.2);
    opacity: 0;
  }
  30% {
    transform: translate(-120%, 80%) scale(0.2);
    z-index: 2;
    opacity: 1;
  }

  80% {
    transform: translate(-80%, 50%) scale(1);
    z-index: 2;
    opacity: 0.5;
  }
  100% {
    transform: translate(-70%, 40%) scale(1);
    z-index: 2;
    opacity: 0;
  }
}

.diamond-confetti {
  width: 20px;
  height: 20px;
  background-color: #e9e9e9;
  position: absolute;
  top: 0;
  left: 0;
}
