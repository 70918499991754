:root {
  // Scale is using this font-size
  --font-size: 0.8em;

  --bg1: #f5f8f7;
  --blue: #3498db;
  --green: #2ecc71;
  --purple: #9b59b6;
  --gold: #f1c40f;
  --red: #e74c3c;
  --orange: #e67e22;
  --shadow1: 0 2px 4px #00000026, 0 3px 6px #0000001f;
  --shadow2: 0 2px 6px #00000044, 0 4px 7px #00000022;
}

.parallaxContainer {
  .highlight {
    background-image: linear-gradient(transparent 60%, #ff99b4 60%);
    background-size: 98% 100%;
    background-repeat: no-repeat;
    background-position: top center;
  }

  .highlight.fontColor1 {
    background-image: linear-gradient(transparent 60%, #3498dbac 60%);
  }
  .highlight.fontColor2 {
    background-image: linear-gradient(transparent 60%, #2ecc708a 60%);
  }
  .highlight.fontColor3 {
    background-image: linear-gradient(transparent 60%, #9c59b68b 60%);
  }
  .highlight.fontColor4 {
    background-image: linear-gradient(transparent 60%, #f1c40fb7 60%);
  }
  h2.fontColor1 {
    color: var(--blue);
  }

  h2.fontColor2 {
    color: var(--green);
  }

  h2.fontColor3 {
    color: var(--purple);
  }

  h2.fontColor4 {
    color: var(--gold);
  }

  h2.fontColor5 {
    color: var(--red);
  }

  h2.fontColor6 {
    color: var(--orange);
  }
  .number {
    font-family: 'maven pro', sans-serif;
    font-size: 10rem;
    font-weight: 900;
    width: 0.9em;
    text-align: center;

    &.fontColor1 {
      color: var(--blue);
    }

    &.fontColor2 {
      color: var(--green);
    }

    &.fontColor3 {
      color: var(--purple);
    }

    &.fontColor4 {
      color: var(--gold);
    }

    &.fontColor5 {
      color: var(--red);
    }

    &.fontColor6 {
      color: var(--orange);
    }
  }

  .coverWrap {
    transform: rotate(130deg);
    position: absolute;
    width: 18em;
    height: 15em;
    margin-top: -10em;
    //   margin-left: -7em;

    .numberCover {
      position: absolute;
      background: var(--bg1);
      width: 18em;
      height: 6em;
      border-radius: 50% 50% 0 0;
      border-bottom: 3px solid #f5f8f7;
      transition: all 0.4s;
      left: -3em;

      &::before {
        position: absolute;
        content: '';
        bottom: 5px;
        left: 4em;
        right: 4em;
        top: 5em;
        box-shadow: 0 0 30px 17px #48668577;
        border-radius: 100px / 10px;
        z-index: -1;
      }

      &::after {
        position: absolute;
        bottom: 0;
        content: '';
        left: -10%;
        width: 120%;
        height: 150%;
        background: radial-gradient(
          at bottom,
          #48668533,
          transparent,
          transparent
        );
        z-index: 1;
      }
    }
  }

  .content {
    //   margin: -3em -3em 1em 7em;
    position: relative;
    // margin: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.7rem;
      text-align: center;
      // margin-bottom: 10px;
      text-transform: uppercase;
      width: 200px;
    }

    p {
      line-height: 1.5em;
    }

    .numberIcon {
      margin-left: 5em;
    }
  }

  .panel-header:hover .numberCover {
    border-radius: 40%;
  }

  .panel-header li {
    // Resize with Ems
    font-size: var(--font-size);
    display: flex;
    //   flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    li {
      width: 100%;
      // max-width: 25em;
      background: var(--bg1);
      border-radius: 0.5em;
      padding: 0.5em;
      z-index: 1;
      transition: all 0.2s;
      list-style: none;
      cursor: pointer;

      .numberWrap {
        position: absolute;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .coverWrap {
        transform: rotate(130deg);
        //   position: absolute;
        width: 18em;
        height: 15em;
        left: -3em;
        top: -1em;

        .numberCover {
          position: absolute;
          background: var(--bg1);
          width: 18em;
          height: 6em;
          border-radius: 50% 50% 0 0;
          border-bottom: 3px solid #f5f8f7;
          transition: all 0.4s;

          &::before {
            position: absolute;
            content: '';
            bottom: 5px;
            left: 4em;
            right: 4em;
            top: 5em;
            box-shadow: 0 0 30px 17px #48668577;
            border-radius: 100px / 10px;
            z-index: -1;
          }

          &::after {
            position: absolute;
            bottom: 0;
            content: '';
            left: -10%;
            width: 120%;
            height: 150%;
            background: radial-gradient(
              at bottom,
              #48668533,
              transparent,
              transparent
            );
            z-index: 1;
          }
        }
      }

      .content {
        margin: 'auto';
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 1.7em;
          font-weight: 500;
          text-align: center;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
      }
    }
  }

  // I like the curved version too, so I made it a hover for fun.
  ul.infoGraphic li:hover .coverWrap .numberCover {
    border-radius: 100%;
  }

  /////////////////////////////////////
  // Fontawesome Icons
  // List: http://astronautweb.co/snippet/font-awesome/

  .icon {
    position: absolute;
    font-size: 2rem;
    text-align: center;
    top: -1.3em;
    //   left: 50%;
    transform: translatex(-50%);

    &:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }
  }

  .iconBulb {
    &:before {
      content: '\f0eb';
    }
  }

  .iconPeace {
    &:before {
      content: '\f25b';
    }
  }
}
