.background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #ffffff, #ece9e6);
  display: flex;
  flex-grow: 1;
  z-index: -1;
}

.background .bgdiv {
  position: absolute;
  border-radius: 100%;
  height: 0;
  filter: blur(240vw);
  opacity: 0.4;
}

.background .bgdiv:nth-child(1) {
  background: linear-gradient(132deg, #008080 0%, #0080c0 100%);
  width: 58%;
  padding-top: 58%;
  left: 100%;
  top: 78%;
  transform: translateX(-50%) translateY(-50%);
}

.background .bgdiv:nth-child(2) {
  background: linear-gradient(132deg, #2c43d 0%, #f6569e 100%);
  width: 68%;
  padding-top: 68%;
  left: 24%;
  top: 46%;
  transform: translateX(-50%) translateY(-50%);
}

.background .bgdiv:nth-child(3) {
  background: linear-gradient(132deg, #5118dc 0%, #5b33f6 100%);
  width: 9%;
  padding-top: 9%;
  left: 20%;
  top: 98%;
  transform: translateX(-50%) translateY(-50%);
}

.background .bgdiv:nth-child(4) {
  background: linear-gradient(132deg, #e364f5 0%, #deb125 100%);
  width: 12%;
  padding-top: 12%;
  left: 1%;
  top: 29%;
  transform: translateX(-50%) translateY(-50%);
}

.background .bgdiv:nth-child(5) {
  background: linear-gradient(132deg, #389718 0%, #2a8a7 100%);
  width: 22%;
  padding-top: 22%;
  left: 44%;
  top: 72%;
  transform: translateX(-50%) translateY(-50%);
}

.background .bgdiv:nth-child(6) {
  background: linear-gradient(132deg, #996654 0%, #4e741b 100%);
  width: 4%;
  padding-top: 4%;
  left: 37%;
  top: 5%;
  transform: translateX(-50%) translateY(-50%);
}
