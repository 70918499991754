.questionsPageLayout {
  .contentEditable {
    transition: transform 0.25s linear;
  }
  .contentEditable:hover {
    outline: 0.5px solid rgb(93, 93, 191);
    transform: scale(1.015);
  }
  .page-card {
    position: relative;
  }
  .page-card:hover {
    background-color: rgba(95, 158, 160, 0.035);
  }
  .page-card:hover .page-action {
    visibility: visible;
  }
}
.sd-boolean,
.sd-boolean-root {
  direction: ltr;
}
// .sd-boolean.sd-boolean--checked .sd-boolean__label--true,
// .sd-boolean:not(.sd-boolean--checked):not(sd-boolean--indeterminate)
//   .sd-boolean__label--false {
//   color: rgba($color: #000000, $alpha: 0.4);
// }
