.hero-title {
  opacity: 1;
  animation: fade 400ms ease-out 1 normal;

  -webkit-animation: fade 400ms ease-out 1 normal;
  -moz-animation: fade 400ms ease-out 1 normal;
  -o-animation: fade 400ms ease-out 1 normal;
  -ms-animation: fade 400ms ease-out 1 normal;
}

.mgdalor-sub-title {
  animation-delay: 50ms;
}

@keyframes fade {
  0% {
    opacity: 0.3;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0.3;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0.3;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fade {
  0% {
    opacity: 0.3;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-ms-keyframes fade {
  0% {
    opacity: 0.3;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1170px) {
  .vertical-timeline-element-content {
    transform: translateX(-125%);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: 0 !important;
    color: red;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date:nth-child(odd) {
    transform: translateX(125%) !important;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date:nth-child(even) {
    transform: translateX(-250%) !important;
  }
}
@media screen and (min-width: 768px) {
  .timeline {
    flex-direction: column;
  }

  .timeline-item {
    flex-direction: row;
    margin-bottom: 60px;
  }

  .timeline-item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .timeline-item .content {
    margin-left: 0;
    margin-right: 20px;
    background-color: #fff;
  }
}

.MuiTableRow-root > .MuiTableCell-root:first-child {
  position: sticky;
  right: 0;
}

.typewriter .subTitle {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  /* animation: typing 3.5s steps(40, end) 6, blink-caret 0.75s step-end infinite; */
}

.filledInfoSection {
  background-image: linear-gradient(to left, #49a3f1, #1a73e8);
  background-attachment: fixed;
}

.filledInfoSectionFlipped {
  background-image: linear-gradient(to right, #49a3f1, #1a73e8);
  background-attachment: fixed;
}
@media screen and (min-width: 1200px) {
  .filledInfoSection,
  .filledInfoSectionFlipped {
    clip-path: polygon(
      0% 0%,
      0% 82.5%,
      1.69492% 84.3293%,
      3.38983% 86.1385%,
      5.08475% 87.9078%,
      6.77966% 89.6179%,
      8.47458% 91.25%,
      10.1695% 92.7862%,
      11.8644% 94.2098%,
      13.5593% 95.505%,
      15.2542% 96.6578%,
      16.9492% 97.6555%,
      18.6441% 98.487%,
      20.339% 99.1435%,
      22.0339% 99.6176%,
      23.7288% 99.9041%,
      25.4237% 100%,
      27.1186% 99.9041%,
      28.8136% 99.6176%,
      30.5085% 99.1435%,
      32.2034% 98.487%,
      33.8983% 97.6555%,
      35.5932% 96.6578%,
      37.2881% 95.505%,
      38.9831% 94.2098%,
      40.678% 92.7862%,
      42.3729% 91.25%,
      44.0678% 89.6179%,
      45.7627% 87.9078%,
      47.4576% 86.1385%,
      49.1525% 84.3293%,
      50.8475% 82.5%,
      52.5424% 80.6708%,
      54.2373% 78.8616%,
      55.9322% 77.0922%,
      57.6271% 75.3821%,
      59.322% 73.75%,
      61.017% 72.2138%,
      62.7119% 70.7902%,
      64.4068% 69.495%,
      66.1017% 68.3422%,
      67.7966% 67.3446%,
      69.4915% 66.513%,
      71.1864% 65.8565%,
      72.8814% 65.3824%,
      74.5763% 65.0959%,
      76.2712% 65%,
      77.9661% 65.0959%,
      79.661% 65.3824%,
      81.3559% 65.8565%,
      83.0509% 66.513%,
      84.7458% 67.3446%,
      86.4407% 68.3422%,
      88.1356% 69.495%,
      89.8305% 70.7902%,
      91.5254% 72.2138%,
      93.2203% 73.75%,
      94.9153% 75.3821%,
      96.6102% 77.0922%,
      98.3051% 78.8616%,
      100% 80.6708%,
      100% 0%
    );
    shape-outside: polygon(
      0% 0%,
      0% 82.5%,
      1.69492% 84.3293%,
      3.38983% 86.1385%,
      5.08475% 87.9078%,
      6.77966% 89.6179%,
      8.47458% 91.25%,
      10.1695% 92.7862%,
      11.8644% 94.2098%,
      13.5593% 95.505%,
      15.2542% 96.6578%,
      16.9492% 97.6555%,
      18.6441% 98.487%,
      20.339% 99.1435%,
      22.0339% 99.6176%,
      23.7288% 99.9041%,
      25.4237% 100%,
      27.1186% 99.9041%,
      28.8136% 99.6176%,
      30.5085% 99.1435%,
      32.2034% 98.487%,
      33.8983% 97.6555%,
      35.5932% 96.6578%,
      37.2881% 95.505%,
      38.9831% 94.2098%,
      40.678% 92.7862%,
      42.3729% 91.25%,
      44.0678% 89.6179%,
      45.7627% 87.9078%,
      47.4576% 86.1385%,
      49.1525% 84.3293%,
      50.8475% 82.5%,
      52.5424% 80.6708%,
      54.2373% 78.8616%,
      55.9322% 77.0922%,
      57.6271% 75.3821%,
      59.322% 73.75%,
      61.017% 72.2138%,
      62.7119% 70.7902%,
      64.4068% 69.495%,
      66.1017% 68.3422%,
      67.7966% 67.3446%,
      69.4915% 66.513%,
      71.1864% 65.8565%,
      72.8814% 65.3824%,
      74.5763% 65.0959%,
      76.2712% 65%,
      77.9661% 65.0959%,
      79.661% 65.3824%,
      81.3559% 65.8565%,
      83.0509% 66.513%,
      84.7458% 67.3446%,
      86.4407% 68.3422%,
      88.1356% 69.495%,
      89.8305% 70.7902%,
      91.5254% 72.2138%,
      93.2203% 73.75%,
      94.9153% 75.3821%,
      96.6102% 77.0922%,
      98.3051% 78.8616%,
      100% 80.6708%,
      100% 0%
    );
  }
}

@media screen and (max-width: 1200px) {
  .filledInfoSection,
  .filledInfoSectionFlipped {
    clip-path: polygon(0% 0%, 100% 0%, 105.1% 95.8%, 0% 100%);
  }
}
.freeConsultCardWrap {
  background-image: linear-gradient(to right, blue, violet);
  padding: 3px;
  animation: gradientBorder 1s linear infinite alternate;
  border-radius: 50%;
}
.freeConsultCard {
  background-attachment: fixed;
  border-image-width: 4px;
  border-radius: 50%;
}

@keyframes gradientBorder {
  0% {
    background-image: linear-gradient(0deg, blue, violet);
  }
  20% {
    background-image: linear-gradient(30deg, blue, violet);
  }
  40% {
    background-image: linear-gradient(60deg, blue, violet);
  }
  60% {
    background-image: linear-gradient(90deg, blue, violet);
  }
  80% {
    background-image: linear-gradient(60deg, blue, violet);
  }
  100% {
    background-image: linear-gradient(30deg, blue, violet);
  }
}

/* .highlighted {
  position: relative;
}

.highlighted:before {
  content: '';
  z-index: -1;
  left: -0.1em;
  top: -0.1em;
  border-width: 2px;
  border-style: solid;
  border-color: red;
  position: absolute;
  border-right-color: transparent;
  width: 95%;
  height: 1em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em 0.1em 0.25em;
}

.highlighted:after {
  content: '';
  z-index: -1;
  left: -0.5em;
  top: 0.1em;
  padding: 0.1em 0.25em;
  border-width: 2px;
  border-style: solid;
  border-color: red;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 100%;
  height: 1em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
} */
