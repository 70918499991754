/* big cursor feature */
.bigCursor {
  cursor: url(./assets/arrow_w.svg), auto !important;
}

body.bigCursor * {
  cursor: inherit;
}
/* big cursor feature */

/* highlight anchors */
body.highlightAnchors a {
  background-color: rgb(53, 48, 48);
  color: rgb(225, 229, 233);
  text-decoration: underline;
}

body.highlightAnchors a:hover {
  background-color: rgb(29, 27, 27);
  color: rgb(229, 235, 242);
  text-decoration: underline;
  text-decoration-color: yellow;
}
/* highlight anchors */

/* letter spacing */
body.bigSpacing * {
  letter-spacing: 0.075rem;
}

body.hugeSpacing * {
  letter-spacing: 0.15rem;
}
/* letter spacing */

/* line spacing */
body.bigLineSpacing * {
  line-height: 2;
}

body.hugeLineSpacing * {
  line-height: 2.5;
}
/* line spacing */

/* color saturation */
body.desaturation * {
  filter: saturate(0);
}
body.lowSaturation * {
  filter: saturate(1.15);
}
body.highSaturation * {
  filter: saturate(1.4);
}
/* color saturation */
